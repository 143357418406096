
import { useToggle } from '@vant/use'
import { computed, defineComponent, onMounted, reactive, Ref, ref, watch } from 'vue'
import { nanoid } from 'nanoid'
import { useRouter } from 'vue-router'
import { ImagePreview } from 'vant'
import { ImageType, BusinessType, Source, CameraOptions } from '@/enum'
import useOpenInfo from '@/hooks/openInfo'
import uploadApi from '@/axios/upload'
import useQueryFileTemp from '@/hooks/useFileTemp'
import useSaveOpenInfo from '@/hooks/useSaveOpenInfo'
import { nameConvert } from '@/utils/tuomin'
import {
  getPictureAction,
  setApptitle,
  isInAppWebview,
  appUploadFileAction
} from '@/utils/native-app'
import { dataURLtoFile, unzip } from '@/utils/file'
import beforeUpload from '@/utils/before-upload'
import useMutationCommon from '@/hooks/useMutationCommon'
import { FileModel } from '@/interface'
import { mutateFn, queryFn } from '@/graphql'
import { Message } from '@/components/Message'

export default defineComponent({
  setup () {
    const router = useRouter()
    const {
      openInfo,
      openType,
      meta,
      step,
      title,
      saveOpenInfoOfVuex,
      saveCommonOfVuex
    } = useOpenInfo()
    const [showBankPicker, showBankPickerToggle] = useToggle()
    // 上传文件
    const [fileActionState, fileActionToggle] = useToggle()
    const form: any = reactive({
      bankName: '',
      otherBankName: '',
      swiftCode: '',
      bankNo: '',
      moneyType: []
    })
    const fileList: Ref<FileModel[]> = ref([])

    // 相机选项
    const actionsList = [
      { key: CameraOptions.CAMERA, name: '拍摄' },
      { key: CameraOptions.PHOTO, name: '相册' },
      { key: CameraOptions.FILE, name: '选取文件' }
    ]

    // 查询数据字典
    const dicResult = ref()
    // 香港银行卡列表
    const bankOptions = ref()
    // 币种类型
    const moneyOptions = ref()
    // 用户信息回填
    const initData = async () => {
      Object.keys(form).forEach(val => {
        const res = openInfo.value[val] ? openInfo.value[val] : form[val]
        form[val] = res
      })
      const res = await queryFn('findDictOpenAccount', {
        markList: ['BANK_INFO', 'MONEY_TYPE']
      })
      dicResult.value = res.data
      bankOptions.value = dicResult.value.BANK_INFO.map((val: { name: string; value: string }) => ({
        text: val.name,
        value: val.value
      }))
      moneyOptions.value = dicResult.value.MONEY_TYPE
    }

    // 姓名脱敏
    const nameConvertFunc = computed(() => {
      const name = openInfo.value.familyName + openInfo.value.givenName
      return nameConvert(name)
    })

    // 判断是否在金马app内
    const isApp = computed(() => isInAppWebview())

    const uploadToApp = () => {
      if (!isApp.value) {
        return
      }
      fileActionToggle(true)
    }

    // 上传图片
    const uploadFile = (file: File) => {
      beforeUpload(file).then((res: any) => {
        const formatFile = new File([res], res.name, { type: res.type })
        fileList.value.push({
          url: '',
          status: 'uploading',
          message: '上传中...'
        })
        const params = {
          file: formatFile,
          imageType: ImageType.ACC_IMAGE_TYPE_201,
          businessType: BusinessType.ACC_FILE_BUSINESS_TYPE_1,
          kindSource: openType.value,
          source: Source.H5
        }
        uploadApi.uploadFile(params).then((res: any) => {
          const { openAccountUploadFile } = res
          if (openAccountUploadFile) {
            fileList.value[fileList.value.length - 1] = {
              url: openAccountUploadFile.data,
              status: 'done'
            }
          } else {
            console.log('上传失败')
            Message({
              direction: 'vertical',
              text: openAccountUploadFile?.reason?.desc || '上传失败，请重试'
            })
          }
        })
      })
    }

    // 上传图片前钩子
    const beforeRead = (file: File) => {
      uploadFile(file)
    }
    const onFileSelect = async (item: { key: string }) => {
      if (item.key === CameraOptions.FILE) {
        const params = {
          imageType: ImageType.ACC_IMAGE_TYPE_201,
          businessType: BusinessType.ACC_FILE_BUSINESS_TYPE_1,
          kindSource: openType.value,
          source: Source.H5
        }
        fileList.value.push({
          url: '',
          status: 'uploading',
          message: '上传中...'
        })
        const { filePath }: any = await appUploadFileAction(params)
        if (filePath.length) {
          fileList.value[fileList.value.length - 1] = {
            url: filePath[0].url,
            status: 'done'
          }
        } else {
          fileList.value.pop()
        }
      } else {
        const url = await getPictureAction(item.key)
        const base64 = unzip(url)
        const imgUrl = `data:image/png;base64,${base64}`
        const png = dataURLtoFile(imgUrl, `${nanoid()}.png`)
        uploadFile(png)
      }
    }

    // 获取缓存图片信息
    const imagesList = useQueryFileTemp({
      kindSource: openType.value
    })
    watch(imagesList, newValue => {
      fileList.value = newValue
        .filter((val: { imageType: number }) => val.imageType === ImageType.ACC_IMAGE_TYPE_201)
        .map((val: { remoteUrl: string }) => ({
          url: val.remoteUrl,
          status: 'done'
        }))
    })

    // 选择银行卡列表
    const onBankConfirm = (val: { text: string; value: string }) => {
      form.bankName = val.text
      form.swiftCode = val.value
      showBankPickerToggle(false)
    }

    // 删除图片
    const handleDel = async (file: { url: string }) => {
      const params = {
        kindSource: openType.value,
        remoteUrl: file.url
      }
      const res = await mutateFn('deleteTempFile', { params })
      const { ok, reason } = res
      if (ok) {
        fileList.value = fileList.value.filter((val: { url: string }) => val.url !== file.url)
        return true
      } else {
        Message({ text: reason?.desc || '请求异常', direction: 'vertical' })
        return false
      }
    }

    const isDisabled = computed(() => {
      // const formStatus = Object.values(form).every((val: any) => val.length);
      let formStatus = form.bankName && form.swiftCode && form.swiftCode && form.moneyType.length
      if (form.swiftCode === 'OTHER') {
        formStatus = formStatus && form.otherBankName
      }
      const fileStatu =
        fileList.value.length && !fileList.value.some((s: FileModel) => s.status === 'uploading')
      return !(formStatus && fileStatu)
    })

    // 保存下一步调用接口
    const saveNext = async () => {
      const params = {
        formType: 'openAccount',
        meta: meta.value,
        content: JSON.stringify(form)
      }
      const common = {
        openType: openType.value,
        openStatus: 1,
        btnText: '身份核实',
        remark: '距离开户成功只差最后一步',
        step
      }
      useSaveOpenInfo(params).then(() => {
        useMutationCommon(common)
        saveCommonOfVuex({ info: common })
        saveOpenInfoOfVuex({ info: form })
        router.push({ name: 'transfer' })
      })
    }

    const handleNext = () => {
      saveNext()
    }

    onMounted(() => {
      setApptitle(title)
      initData()
    })

    // 保存步骤信息回调
    // saveOpenInfoDoneFunc(() => {
    //   saveOpenInfoOfVuex({ info: form });
    //   router.push('/transfer');
    // });
    const viewExp = () => {
      ImagePreview({
        images: [
          require('../../assets/images/1huifengHKCard.png'),
          require('../../assets/images/2ChinaBankCard.png'),
          require('../../assets/images/3huifengCard.png')
        ],
        closeable: true
      })
    }
    return {
      viewExp,
      openInfo,
      initData,
      form,
      fileList,
      showBankPicker,
      showBankPickerToggle,
      bankOptions,
      moneyOptions,
      onBankConfirm,
      handleNext,
      handleDel,
      isDisabled,
      nameConvertFunc,
      fileActionState,
      uploadToApp,
      isApp,
      fileActionToggle,
      uploadFile,
      beforeRead,
      onFileSelect,
      actionsList
    }
  }
})
